<template>
  <div :class="$style.recipients">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="7rem"
      :class="$style.form"
    >
      <el-form-item label="Фамилия" prop="lastName">
        <el-input v-model="form.lastName" />
      </el-form-item>
      <el-form-item label="Имя" prop="firstName">
        <el-input v-model="form.firstName" />
      </el-form-item>
      <el-form-item label="Отчество" prop="middleName">
        <el-input v-model="form.middleName"> </el-input>
      </el-form-item>
      <el-form-item label="Телефон" prop="phone">
        <el-input v-model="form.phone" />
      </el-form-item>
      <el-form-item label="Почта" prop="email">
        <el-input v-model="form.email" />
        <el-button size="small" type="secondary" @click="getDealerEmail()"
          >Заполнить</el-button
        >
      </el-form-item>
    </el-form>
    <div :class="$style.wrapper">
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
        >Сохранить контент</el-button
      >
    </div>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import regexp from '@/helpers/regexp.js'

export default {
  components: {},
  mixins: [notifications],
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
        email: ''
      },
      rules: {
        firstName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        lastName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          {
            min: 8,
            message: 'Не корректный номер телефона',
            trigger: 'change'
          }
        ],
        email: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          {
            pattern: regexp.email,
            message: 'Введите корретный email',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    async getDealerEmail() {
      const loading = this.$loading({
        lock: true
      })
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getById(this.$route.params.id)

      loading.close()
      if (error) return
      this.form.email = value?.email ?? ''
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          const data = {
            ...this.form,
            phone:
              this.form.phone[0] === '+'
                ? this.form.phone
                : `+${this.form.phone}`
          }

          const result =
            await delivery.AddwineCore.DealersActions.createDealerRecipient(
              this.$route.params.id,
              data
            )

          loading.close()

          if (result.error) return

          this.showNotification('Получатель успешно создан', 'success')
          this.$router.push(
            `/sellers/dealers/${this.$route.params.id}/recipients`
          )
        }
      })
    }
  }
}
</script>
<style lang="scss" module>
.recipients {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
